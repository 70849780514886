@font-face {
  font-family: MyWebFont;
  font-variation-settings: "wght" 300;
  src: url("../../vendors/dazzed_trial/DazzedTRIALVF.woff") format("woff"), url("../../vendors/dazzed_trial/DazzedTRIALVF.ttf") format("truetype");
}

h1,
p {
  font-family: MyWebFont, sans-serif;
}

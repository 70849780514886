.nx-user-profile-tabs {
  background-color: #191b23;
  margin: 100px 0 0;

  ul {
    list-style: none;
    display: block;
    padding: 0;
    margin: 0;
  }

  li {
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 120%;
    display: inline-block;
    padding: 12px;
  }

  li.active {
    border-bottom: 2px solid #e9d942;
    font-weight: 600;
  }

  a {
    text-decoration: none;
    text-transform: none;
  }
}

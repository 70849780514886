.nx-page-profile {
  padding: 0 24px 100px;

  button[type="submit"] {
    background-color: #191b23;
    border-radius: 32px;
    font-size: 15px;
    font-weight: 450;
    line-height: 120%;
    display: block;
    padding: 12px 32px;
    margin: 0 auto;
  }

  button[type="submit"]:hover {
    opacity: 0.7;
  }

  button[type="submit"]:active {
    opacity: 0.4;
  }

  .invalid-error {
    color: #dc3545;
    font-size: 0.875em;
    width: 100%;
    margin-top: 0.25rem;
  }

  .help-text {
    color: #484a54;
    font-size: 12px;
    width: 100%;
    margin-top: 0.25rem;
  }

  .accordion {
    text-align: left;
    padding-bottom: 12px;

    .accordion-item {
      overflow: hidden;
      padding: 12px 24px;

      h2 span {
        font-size: 24px;
        font-weight: 450;
        line-height: 120%;
        padding: 12px 0;
      }
    }

    .accordion-item:first-of-type {
      border-top-left-radius: 32px;
      border-top-right-radius: 32px;
    }

    .accordion-item:last-of-type {
      border-bottom-right-radius: 32px;
      border-bottom-left-radius: 32px;
    }

    .accordion-button:not(.collapsed) {
      color: #000;
    }

    .accordion-button:not(.collapsed)::after {
      background-image: var(--bs-accordion-btn-icon);
    }

    .accordion-item:first-of-type .accordion-button {
      background-color: #fff;
      border-top-left-radius: 32px;
      border-top-right-radius: 32px;
      box-shadow: none;
    }
  }
}

.featured-wrapper {
  max-width: 100%;
  margin: 0 auto;
  background-color: #191b23;
  padding: 45px 0 0;

  h2 {
    color: #fff;
    font-size: 34px;
    font-weight: 350;
    line-height: 120%;
    text-align: left;
    margin: 0 0 42px;
  }

  .featured-carousel {
    display: flex;
    gap: 25px;
    overflow-x: scroll;
    padding: 0 25px 16px;
    cursor: pointer;

    &::-webkit-scrollbar {
      height: 16px;
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: $yellow-dark;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    &::-webkit-scrollbar-thumb:horizontal {
      background-color: #191b23;
      border-radius: 10px;
      width: 100px;
      cursor: pointer;
    }

    .slide {
      color: #fff;
      text-decoration: none;
      display: block;
      flex: 0 0 400px;
      max-width: 100%;
      z-index: 1;

      .nx-slide-item {
        color: #fff;
        text-decoration: none;
        text-transform: none;
        display: flex;
        flex: 0 0 400px;
        flex-direction: column;
        max-width: 100%;

        &:hover {
          opacity: 0.8;
        }

        .thumb {
          width: 100%;
          height: 300px;
          background-size: cover;
          background-position: center center;
          border-radius: 32px;
          margin-bottom: 32px;
        }

        .cont {
          display: flex;
          gap: 16px;

          .decor-col {
            .decor {
              background-color: white;
              border-radius: 50%;
              width: 30px;
              height: 30px;
              margin-top: 3px;
            }
          }

          .l-cont {
            text-align: left;

            p {
              font-size: 24px;
              font-weight: 350;
              line-height: 150%;
              margin-bottom: 22px;
            }

            .tag-location {
              font-size: 14px;
              font-weight: 350;
              line-height: 100%;
              height: 32px;
              margin: 0 0 8px;
            }

            .nx-featured-date {
              font-size: 14px;
              font-weight: 300;
              line-height: 120%;
              text-transform: uppercase;
              display: flex;
              flex-direction: row;
              align-items: center;
              margin: 22px 0;

              > span {
                margin: 0 0 0 6px;
              }
            }

            .location-tag {
              display: inline-flex;
              padding: 5px 10px;
              border-radius: 25px;
              border: 1px solid #fff;
              gap: 8px 10px;
            }

            .min-desc {
              color: #fff;
              font-size: 14px;
              font-weight: 330;
              line-height: 120%;
              text-transform: uppercase;
              margin: 16px 0 0;

              > span {
                display: inline-block;
                padding: 0 16px 8px 0;
              }
            }
          }

          .r-cont {
            .date-tag {
              border: 1px solid white;
              border-radius: 4px;
              font-size: 14px;
              text-transform: uppercase;
              word-break: break-word;
              width: 70px;
              padding: 8px;
            }
          }
        }
      }
    }
  }

  @media (min-width: 576px) {
    .featured-carousel {
      padding: 0 calc((100vw - 540px) / 2) 16px;
    }
  }

  @media (min-width: 768px) {
    .featured-carousel {
      padding: 0 calc((100vw - 720px) / 2) 16px;
    }
  }

  @media (min-width: 992px) {
    .featured-carousel {
      padding: 0 calc((100vw - 960px) / 2) 16px;
    }
  }

  @media (min-width: 1200px) {
    .featured-carousel {
      padding: 0 calc((100vw - 1140px) / 2) 16px;
    }
  }

  @media (min-width: 1400px) {
    .featured-carousel {
      padding: 0 calc((100vw - 1320px) / 2) 16px;
    }
  }
}

.page-wrapper-events.event-page {
  .featured-wrapper,
  .featured-wrapper-empty {
    margin: 72px auto 0;
  }
}

.page-wrapper-events.events-list-page {
  .featured-wrapper,
  .featured-wrapper-empty {
    margin: 156px auto 0;
  }
}

.cart-sidebar {
  position: fixed;
  top: 100px;
  height: calc((var(--vh, 1vh) * 100) - 100px);
  background: #fff;
  z-index: 10;
  right: 0;
  width: 500px;
  box-shadow: rgb(0 0 0 / 25%) 0 0 5px -1px, rgb(0 0 0 / 3%) 0 1px 3px -1px;
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);

  &.open {
    transform: translateX(0);
  }

  .cart-header {
    background-color: #191b23;
    color: #fff;
    display: flex;
    justify-content: space-between;
    height: 75px;
    align-items: center;
    padding: 0 20px;
    transition: auto;

    &.cart-header-white {
      background-color: #fff;
      color: black;
    }

    h2 {
      display: inline;
      font-size: 24px;
      font-weight: 450;
      line-height: 36px;
    }

    .header-steps {
      font-style: normal;
      font-weight: 450;
      font-size: 12px;
      line-height: 18px;
    }
  }

  .cart-preview-main {
    overflow-y: scroll;
    height: calc((var(--vh, 1vh) * 100) - 250px);
  }

  .cart-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #191b23;
    color: #fff;
    display: flex;
    justify-content: space-between;
    height: 75px;
    align-items: center;
    padding: 0 20px;

    img {
      width: 15px;
      height: 15px;
    }

    &.white {
      background-color: #fff;
      color: #191b23;
    }
  }

  .footer-return-event {
    text-align: left;
    padding: 20px;
    cursor: pointer;
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 30px;
    color: #191b23;

    img {
      width: 16px;
    }
  }

  .sidebar-close {
    cursor: pointer;
  }

  .step {
    padding: 20px;
  }

  .cart-preview-title {
    font-style: normal;
    font-weight: 450;
    font-size: 20px;
    line-height: 30px;
    color: #191b23;
    text-align: left;
  }

  .checkout-ticket-container {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 16px 24px;
    margin-bottom: 24px;
    width: 100%;
    background: #f4f5f9;
    border: 1px solid #e0e1e9;
    border-radius: 32px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

    .checkout-ticket-title {
      text-align: left;
      font-style: normal;
      font-weight: 450;
      font-size: 16px;
      line-height: 24px;
      color: #191b23;
    }

    .checkout-ticket-date {
      color: #191b23;
      font-size: 12px;
      font-style: normal;
      font-weight: 450;
      line-height: 20px;
      text-transform: uppercase;
    }

    .checkout-ticket-separator {
      width: 100%;
      background-color: #c4c7cf;
    }

    .checkout-ticket-footer {
      width: 100%;

      .checkout-ticket-footer-label {
        text-align: left;
        font-style: normal;
        font-weight: 330;
        font-size: 12px;
        line-height: 18px;
        text-transform: uppercase;
        color: #191b23;
      }
    }

    .checkout-delete-ticket {
      cursor: pointer;
    }

    .nx-event-btn a {
      border: 1px solid #c4c7cf;
      border-radius: 32px;
      color: #191b23;
      font-size: 16px;
      font-weight: 120%;
      line-height: 120%;
      text-decoration: none;
      text-transform: none;
      transition:
        background-color 0.3s,
        border-color 0.3s,
        color 0.3s,
        opacity 0.3s;
      padding: 6px 16px;
    }

    .nx-event-btn a:hover {
      background-color: #191b23;
      border: 1px solid #191b23;
      color: #fff;
    }

    .nx-event-btn a:active {
      opacity: 0.5;
    }

    .checkout-ticket-loading {
      display: none;
      position: absolute;
      text-align: center;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      z-index: 999;
      background-color: rgb(0 0 0 / 20%);
      left: 0;
      top: 0;
      border-radius: 32px;
    }
  }

  .btn-previous {
    background-color: #373739;
    border: 1px solid #373739;
    border-radius: 32px;
    color: #fff;
    font-size: 16px;
    font-weight: 450;
    line-height: 120%;
    text-decoration: none;
    white-space: nowrap;
    outline: none;
    display: block;
    padding: 10px 16px;
    margin: 0;

    &:hover {
      background-color: #6c6e79;
    }
  }

  .btn-next {
    background-color: #fff;
    border: 1px solid #191a24;
    border-radius: 32px;
    color: #191b23;
    font-size: 16px;
    font-weight: 450;
    line-height: 120%;
    text-decoration: none;
    white-space: nowrap;
    outline: none;
    display: block;
    padding: 10px 16px;
    margin: 0;
    margin-left: auto;

    &:hover {
      background-color: #e0e1e9;
    }
  }

  .sticky-message {
    margin: 0 auto;
    min-height: 75px;
    width: 100%;
    display: flex;
    border-top: 4px solid #191b23;
    background-color: white;
    border-radius: 4px;
    padding: 20px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-direction: column;
    transition: auto;

    .content {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;

      .msg {
        align-self: center;

        ul {
          list-style: none;
          font-size: 14px;
          text-align: left;
          padding: 0;

          li {
            margin-bottom: 5px;
          }
        }
      }

      p {
        margin: o;
      }
    }
  }

  .order-header {
    text-align: left;
    font-style: normal;
    font-weight: 450;
    font-size: 24px;
    line-height: 36px;
    color: #191b23;
  }

  .order-description {
    text-align: left;
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 18px;
    color: #191b23;
    margin-bottom: 1rem;
  }

  .order-user {
    display: flex;
    margin-bottom: 1rem;
    margin-top: 1rem;

    h3 {
      text-align: left;
      font-style: normal;
      font-weight: 450;
      font-size: 20px;
      line-height: 30px;
      color: #191b23;
    }
  }

  .nx-accordion-header-icon {
    background-color: #000;
    border-radius: 100%;
    color: #fff;
    line-height: 32px;
    text-align: center;
    display: inline-block;
    width: 32px;
    height: 32px;
    margin: 0 12px 0 0;
  }
}

.input-stepper {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    padding: 0 10px;
  }

  button {
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    left: 63px;
    top: 0;
    background: #fff;
    border: 1px solid #c4c7cf;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.info-message-card {
  background-color: #fff9c1;
  color: #191b23;
  font-style: normal;
  font-weight: 330;
  font-size: 14px;
  line-height: 24px;
  padding: 24px;
  border-radius: 32px;

  .info-message {
    width: 14px;
    margin-right: 10px;
  }
}

@media (max-width: 992px) {
  .cart-sidebar {
    width: 100%;
  }
}

.nx-page-mytickets {
  .nx-mytickets-header {
    box-shadow: 0 0 1px rgba(25 27 35 / 16%), 0 1px 2px rgba(25 27 35 / 12%);
    padding: 24px;

    .nx-mytickets-header-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .nx-mytickets-title h1 {
        font-size: 34px;
        line-height: 150%;
        text-align: left;
        word-break: break-word;
      }

      @media (max-width: 400px) {
        .nx-mytickets-title h1 {
          font-size: 28px;
          line-height: 150%;
        }
      }

      .nx-mytickets-close a {
        background-color: transparent;
        border: 1px solid #c4c7cf;
        border-radius: 4px;
        font-size: 0;
        line-height: 0;
        text-decoration: none;
        display: inline-block;
        transition: all 0.3s;
        padding: 8px;
      }

      .nx-mytickets-close a:hover {
        background-color: #191b23;
        border: 1px solid #191b23;

        .nx-icon-close {
          filter: invert(1);
        }
      }

      .nx-mytickets-close a:active {
        opacity: 0.5;
      }
    }
  }

  .nx-ticket {
    text-align: left;
    max-width: 600px;
    padding: 12px 32px;
    margin: 0 auto 64px;

    .nx-ticket-number {
      h2 {
        font-size: 32px;
        line-height: 120%;
        margin: 0 0 24px;
      }

      span {
        display: inline;
      }
    }

    .nx-ticket-description {
      font-size: 24px;
      line-height: 150%;
      margin: 0 0 24px;
    }

    .nx-ticket-date {
      font-size: 14px;
      line-height: 120%;
      text-transform: uppercase;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 0 16px;

      img {
        width: 15px;
        margin: 0 8px 0 0;
      }
    }

    .nx-ticket-location {
      font-size: 14px;
      line-height: 120%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0;

      img {
        width: 15px;
        margin: 0 8px 0 0;
      }
    }
  }

  @media (max-width: 400px) {
    .nx-ticket {
      padding: 0 12px;

      .nx-ticket-number {
        h2 {
          font-size: 28px;
          line-height: 120%;
        }
      }

      .nx-ticket-description {
        font-size: 18px;
        line-height: 150%;
      }

      .nx-ticket-date,
      .nx-ticket-location {
        font-size: 12px;
        line-height: 120%;
      }
    }
  }
}

body {
  height: 100%;
  background-color: white !important;

  &.sidebar-open {
    overflow: hidden;
  }
}

* {
  transition: all 0.5s ease;
}

*:focus {
  outline: none;
}

p {
  font-size: 16px;
}

a {
  --bs-link-color: #000;
  --bs-link-hover-color: #000;

  text-transform: uppercase;
}

.form-check-input::after,
.form-check-input[type="checkbox"]:checked::after {
  display: none;
}

.form-check-input:focus {
  border-color: rgba(0 0 0 / 25%) !important;
  box-shadow: none !important;
}

.form-check-input:checked[type="checkbox"],
.form-check-input[type="checkbox"]:checked:focus {
  background-color: #e9d942;
  border-color: #e9d942;
}

.form-check-input:focus::before {
  box-shadow: none;
}

.form-check-input:checked:focus::before {
  box-shadow: none;
  display: none;
}

.loader {
  margin: 5px auto;
  border: 2px solid #ddd;
  border-radius: 50%;
  border-top: 3px solid #666;
  width: 15px;
  height: 15px;
  animation: new-center 1s linear infinite;
  transition: auto;
}

@keyframes new-center {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.nx-gcaptcha-text {
  font-size: 12px;
  font-weight: 450;
  line-height: 120%;
  display: block;
  opacity: 0.7;
  margin: 24px 0 0;
}

.text-center {
  text-align: center;
}

@media (min-width: 768px) {
  .text-md-right {
    text-align: right;
  }
}

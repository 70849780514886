$borders: 2px solid black;

.address-section {
  padding: 45px 0;

  .container {
    border-top: $borders;
    border-bottom: $borders;
    padding: 35px 0;

    .nx-contact-info {
      text-align: left;

      a {
        color: #6c6e79;
        font-size: 16px;
        text-decoration: none;
        text-transform: uppercase;
        word-break: break-word;

        &:hover {
          text-decoration: underline;
        }
      }

      a[href^="mailto:"]::before {
        content: "";
        background-image: url("/vendors/images/icons/envelope.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        vertical-align: middle;
        display: inline-block;
        width: 30px;
        height: 28px;
        margin: 0 2px 2px 0;
      }

      p:last-of-type {
        margin-bottom: 0;
      }
    }

    .nx-contact-policy-mention {
      font-size: 14px;
      font-weight: 400;
      line-height: 125%;
      text-align: left;
      margin: 1em 0 0;

      .nx-contact-policy-mention-link {
        color: #6c6e79;
        text-transform: none;
        word-break: break-word;
      }
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 8px;

    li:first-child {
      font-size: 20px;
    }

    li:nth-child(2) {
      font-size: 16px;
    }

    li:nth-child(3) {
      font-size: 20px;
      color: gray;
      text-transform: uppercase;
    }
  }

  @media (max-width: 576px) {
    .container {
      padding: 35px 0;

      ul {
        li {
          font-size: 90%;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .address-section {
    padding: 45px 0;
  }
}

.nx-bg-black {
  background-color: #191b23;
  color: #fff;
}

.subscription-section {
  background-color: #191b23;
  color: #fff;
  padding: 45px 0;

  .container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0;

    #mce-responses {
      max-width: 322px;
    }

    .mce-law25-unsubinfo {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      gap: 24px;
      padding: 12px 0;

      label {
        font-size: 12px;
        line-height: 120%;
        text-align: left;
        text-transform: none;
        cursor: pointer;
      }
    }

    .mce-error-response {
      font-size: 12px;
      line-height: 120%;
      text-align: left;
      padding: 12px 0;
    }

    .nx-contact-newsletter-content {
      text-align: left;

      p:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .top-section {
    display: flex;
    gap: 100px;

    .left-section {
      display: flex;
      text-align: left;
      gap: 25px;
      flex-direction: column;
      max-width: 300px;
      width: 100%;
    }

    .right-section {
      width: calc(100% - 300px);
      height: 4px;
      position: relative;
      top: 28px;
      right: 0;

      div {
        background-color: #fff7ac;
        width: 100%;
        height: 4px;
      }
    }
  }

  form {
    .input-group {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      -webkit-box-align: stretch;
      align-items: stretch;
      width: 100%;
      border-bottom: 1px solid #fff;
      justify-content: space-between;

      input {
        background-color: transparent;
        border: 0;
        padding: 10px 4px;
        color: white;

        &::placeholder {
          color: white;
        }
      }

      button {
        border: 0;
        width: 16px;
        background-color: transparent;
      }
    }
  }

  h2 {
    font-size: 38px;
    font-weight: 350;
    line-height: 150%;
  }
}

@media (max-width: 576px) {
  .subscription-section {
    padding: 45px 0;
  }
}

.nx-error-page {
  .nx-error-detail-banner {
    background-color: #191b23;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 200px;
    margin: 100px 0 0;
  }

  .content-header {
    margin: 48px auto 24px;

    .circle-art {
      > span {
        background-color: #ff1044;
      }
    }
  }

  .min-container {
    margin: 0 auto 48px;

    .nx-btn-gotohome {
      background-color: #191a24;
      border: 1px solid #191a24;
      border-radius: 32px;
      color: #fff;
      font-size: 16px;
      font-weight: 450;
      line-height: 120%;
      text-decoration: none;
      white-space: nowrap;
      outline: none;
      display: inline-block;
      justify-content: center;
      align-items: center;
      padding: 10px 16px;
      margin: 24px 0 0;

      &:disabled {
        opacity: 0.6;
      }

      &:not(:disabled):hover {
        background-color: #2b2e38;
        border-color: #2b2e38;
      }

      &:not(:disabled):active {
        background-color: #2b2e38;
        border-color: #2b2e38;
        opacity: 0.7;
      }
    }
  }
}

.top-navigation {
  height: 100px;
  background-color: white;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 11;
  box-shadow: rgba(0 0 0 / 25%) 0 0 5px -1px, rgba(0 0 0 / 3%) 0 1px 3px -1px;

  .burger-bars {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    img {
      max-width: 161px;
      width: 100%;
    }
  }

  .nav-panel-mob {
    flex-direction: column;
    background-color: #f4f5f9;
    padding: 50px;
    position: fixed;
    right: 0;
    width: 33%;
    height: calc((var(--vh, 1vh) * 100) - 100px);
    top: 99px;
    display: none;
    text-align: left;
    z-index: 99;

    ul {
      padding: 0;
      margin: 0 0 16px;
      text-align: left;
      line-height: 50px;

      li {
        list-style: none;
        border-bottom: 1px solid #b7b8bb;

        a {
          color: black;
          font-size: 18px;
          text-transform: uppercase;
          text-decoration: none;
        }
      }
    }

    .lang-btn {
      background-color: transparent;
      border: 1px solid #a9abb6;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 450;
      line-height: 100%;
      padding: 14px;
    }
  }

  .cont-links {
    ul {
      display: inline-flex;
      margin: 0;
      padding: 0;
      gap: 24px;

      &:first-child {
        margin-right: 52px;
      }

      li {
        list-style: none;

        a {
          color: black;
          text-transform: uppercase;
          text-decoration: none;
        }
      }
    }

    .nx-topbar-iconmenu {
      li {
        a {
          display: block;
          width: 25px;
          height: 25px;
        }
      }
    }

    .badge-icon {
      background: #327864;
      color: white;
      font-size: 8px;
      border-radius: 50%;
      height: 16px;
      width: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      margin-top: -30px;
      margin-left: 15px;
    }
  }

  @media (min-width: 992px) {
    .hide-desk {
      display: none !important;
    }
  }

  @media (max-width: 992px) {
    .hide-mob {
      display: none !important;
    }

    .nav-panel-mob {
      width: 100%;
      padding: 16px;
    }

    .logo {
      img {
        max-width: 123px;
        width: 100%;
      }
    }
  }

  @media (max-width: 800px) {
    .logo {
      max-width: 100px;
    }
  }
}

.user-overlay {
  position: fixed;
  width: 100%;
  height: calc((var(--vh, 1vh) * 100));
  background-color: rgba(0 0 0 / 70%);
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  display: none;

  &.show {
    display: flex;
    z-index: 100;
  }

  form {
    width: inherit;
    margin: 16px;
  }

  .card-a {
    border-radius: 30px;
    background-color: white;
    width: 100%;
    display: inline-flex;
    max-width: 516px;
    flex-direction: column;
    padding: 33px 24px;
    justify-content: space-between;

    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      margin-bottom: 24px;

      .ch-1 {
        text-align: left;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        img {
          margin-right: 10px;
        }
      }

      .ch-2 {
        margin-left: auto;

        @media (max-width: 516px) {
          .btn-white {
            font-size: 12px;
          }
        }
      }

      @media (max-width: 516px) {
        .ch-1 {
          font-size: 14px;
        }

        .ch-2 {
          .btn-white {
            font-size: 12px;
          }
        }
      }
    }

    .card-content {
      display: flex;
      flex-direction: column;
      padding: 16px 0;
      gap: 16px;

      .nav-links {
        color: black;
        font-size: 14px;
        line-height: 100%;
        text-decoration: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
      }
    }

    .card-footer {
      text-align: center;
    }
  }

  @media (max-width: 516px) {
    .card-a {
      padding: 16px;
    }
  }

  .input-group {
    flex-direction: column;
    text-align: left;

    input {
      border: 0;
      border-bottom: 1px solid #333;
      padding: 6px;
      font-size: 14px;
    }

    .error-msg {
      color: red;
      font-size: 14px;
      padding-top: 6px;
    }
  }

  .help-link {
    font-size: 12px;
    color: black;
    text-align: left;
  }

  .user-overlay-close {
    width: 35px;
    height: 35px;
    border: 1px solid #a9abb6;
    border-radius: 4px;
    font-size: 18px;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    padding: 8px;
  }
}

.event-header {
  min-height: 430px;
  background-size: cover;
  background-position: center;
}

.page-wrapper-events {
  .event-header {
    margin-top: 275px;
  }
}

%btn-ui {
  display: inline-flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  text-transform: none;
}

.btn-ui {
  @extend %btn-ui;

  &.s {
    size: 12px;
    min-width: 56px;
    font-size: 16px;
    min-height: 44px;
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (max-width: 599px) {
    &.s {
      font-size: 14px;
      min-height: 44px;
      padding: 8px 12px;
    }
  }

  &.m {
    size: 14px;
    padding: 8px 12px;
    min-width: 70px;
    height: 32px;
  }

  &.l {
    size: 16px;
    padding: 10px 16px;
    min-width: 85px;
    height: 44px;
  }

  &.solid-yellow {
    background-color: #fff7ac;

    &:hover {
      background-color: #e9d942;
    }
  }

  &.outline-fill-yellow {
    background-color: transparent;
    border: 1px solid #e9d942;

    &:hover {
      background-color: #fffde5;
    }
  }

  &.outline-yellow {
    background-color: transparent;
    border: 1px solid #e9d942;

    &:hover {
      background-color: #f4f5f9;
    }
  }

  &.transparent-yellow {
    background-color: transparent;
    border: 1px solid transparent;

    &:hover {
      background-color: #fffde5;
    }
  }
}

%tag {
  gap: 2px;
  font-size: 16px;
  height: 44px;
  border-radius: 32px;
  display: inline-flex;
  align-items: center;
  width: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.tag-location {
  @extend %tag;

  text-decoration: none;
  text-transform: none;

  &.transparent-white {
    border: 1px solid white;
    color: white;
  }

  &.transparent-black {
    border: 1px solid black;
    color: black;
  }

  &.black {
    border: 1px solid black;
    color: black;
  }
}

.price-tag {
  @extend %tag;

  font-family: Inter, sans-serif;

  &.transparent-black {
    border: 1px solid black;
    color: black;
  }

  &.transparent-white {
    border: 1px solid white;
    color: white;
  }
}

@media (max-width: 599px) {
  .price-tag {
    font-size: 14px;
    padding: 8px 12px;
  }
}

.btn-a {
  background-color: white;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 8px 16px;
  font-size: 16px;
  text-decoration: none;
  text-transform: none;
  color: black;
  border-radius: 30px;

  &:hover {
    background-color: green;
  }
}

.btn-yellow {
  background-color: #fff7ac;

  &:hover {
    background-color: #b0a853;
    color: #000;
  }
}

.btn-black {
  background-color: #171717;
  color: white;
  border: 0;
  width: 100%;
  max-width: 200px;
  height: 44px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #000;
  }
}

.btn-white {
  background-color: white;
  color: black;
  border: 1px solid #c4c7cf;
  width: 100%;
  max-width: 200px;
  height: 44px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #cfcfcf;
    color: black;
  }
}

.btn-b {
  width: 100%;
  font-style: normal;
  font-weight: 450;
  line-height: 36px;
  color: #191b23;
  text-decoration: none;
  border: 1px solid #a9abb6;
  border-radius: 4px;
  font-size: 18px;
  background-color: transparent;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  max-width: 300px;
}

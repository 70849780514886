.nx-about-main-content {
  font-size: 24px;
  font-weight: 450;
  line-height: 120%;
  text-align: left;
  margin: 48px 0;

  h2 {
    font-size: 24px;
    font-weight: 450;
    line-height: 120%;
    margin: 24px 0;
  }

  img {
    border-radius: 32px;
    max-width: 100%;
    height: auto;

    &.full-width {
      display: block;
      margin: 32px auto;
    }
  }

  & a {
    color: #2b2e38;
    text-decoration: none;
    font-size: 16px;

    &::before {
      content: "";
      background-color: #2b2e38;
      mask-image: url("/vendors/images/icons/link-chain.svg");
      vertical-align: middle;
      display: inline-block;
      width: 24px;
      height: 25px;
      position: relative;
      top: -0.1em;
      transition: all 0.5s;
      margin: 0 3px 0 0;
    }
  }

  & a:hover {
    text-decoration: underline;
    color: #2b2e38;

    &::before {
      background-color: #2b2e38;
    }
  }
}

.nx-about-secondary-content-image {
  img {
    border-radius: 32px;
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto 48px;
  }
}

.nx-about-secondary-content {
  font-size: 24px;
  font-weight: 450;
  line-height: 120%;
  text-align: left;
  margin: 0 0 48px;

  h2 {
    font-size: 24px;
    font-weight: 450;
    line-height: 120%;
    margin: 0 0 24px;
  }
}

.nx-about-banner {
  text-align: left;
  padding: 48px 0;

  .nx-about-banner-title {
    position: relative;

    h2 {
      font-size: 38px;
      font-weight: 450;
      line-height: 120%;
      max-width: 411px;
      margin: 0 0 24px;
    }

    .nx-line {
      background-color: $yellow;
      display: block;
      width: calc(100% - 431px);
      height: 4px;
      position: absolute;
      top: 22px;
      right: 0;
    }

    @media (max-width: 767px) {
      h2 {
        font-size: 24px;
        line-height: 120%;
        max-width: 258px;
      }

      .nx-line {
        width: calc(100% - 278px);
        top: 13px;
      }
    }
  }

  .nx-about-banner-text {
    font-size: 16px;
    font-weight: 450;
    line-height: 150%;
  }
}

.nx-container-about-video {
  .nx-about-video-youtube {
    border-radius: 32px;
    text-align: center;
    overflow: hidden;
    width: 100%;
    position: relative;
    padding: 0 0 56.25%;
    margin: 32px auto;

    iframe {
      border: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

@media (max-width: 767px) {
  .nx-container-about-video {
    max-width: none;
    padding-left: 0;
    padding-right: 0;

    .row {
      margin-left: 0;
      margin-right: 0;

      .col-12 {
        padding-left: 0;
        padding-right: 0;

        .nx-about-video-youtube {
          border-radius: 0;
          height: calc((var(--vh, 1vh) * 100) - 100px);
          padding: 0;
          margin: 0 auto;

          iframe {
            height: calc((var(--vh, 1vh) * 100) - 100px);
          }
        }
      }
    }
  }
}

.nx-about-locations {
  text-align: center;

  .nx-about-location {
    background-color: #327864;
    color: #fff;
    padding: 48px 0;

    .nx-about-location-title {
      h2 {
        font-size: 38px;
        font-weight: 450;
        line-height: 120%;
        margin: 0 0 12px;
      }
    }

    .nx-about-location-website {
      font-size: 18px;
      font-weight: 450;
      line-height: 120%;
      word-break: break-word;

      a {
        color: inherit;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .nx-about-toggle {
      padding: 32px 0 0;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }

      .nx-about-toggle-open {
        display: inline-block;
        position: relative;
      }

      .nx-about-toggle-close {
        display: none;
        position: relative;
      }
    }

    &.nx-open {
      .nx-about-toggle {
        .nx-about-toggle-open {
          display: none;
        }

        .nx-about-toggle-close {
          display: inline-block;
        }
      }
    }

    .nx-about-location-hiddenpart {
      margin: 0;

      .nx-about-location-text {
        font-size: 16px;
        font-weight: 450;
        line-height: 150%;
        padding: 32px 0 0;

        h3 {
          font-size: 24px;
          font-weight: 450;
          line-height: 150%;
          margin: 0 0 12px;
        }

        & a {
          color: var(--nx-colorbright);
          text-decoration: none;
          font-size: 16px;

          &::before {
            content: "";
            background-color: var(--nx-colorbright);
            mask-image: url("/vendors/images/icons/link-chain.svg");
            vertical-align: middle;
            display: inline-block;
            width: 24px;
            height: 25px;
            transition: all 0.5s;
            margin: 0 4px 2px 0;
          }

          &:hover {
            text-decoration: underline;
            color: var(--nx-colorbright);

            &::before {
              background-color: var(--nx-colorbright);
            }
          }
        }
      }

      .nx-container-about-carousel {
        overflow: hidden;

        .nx-events-detail-carousel {
          margin: 40px 0 8px;

          .nx-events-detail-carousel-wrapper {
            border-radius: 32px;
            text-align: center;
            overflow: hidden;
            position: relative;
            margin: 0 0 16px;

            .carousel-inner {
              .carousel-item {
                max-width: 100%;
                max-height: 100%;

                .nx-events-detail-carousel-item-wrapper {
                  text-align: center;
                  max-width: 100%;
                  max-height: 100%;

                  .nx-events-detail-carousel-image {
                    border-radius: 32px;
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: contain;
                    object-position: center;
                  }
                }
              }
            }

            .carousel-control-prev,
            .carousel-control-next {
              opacity: 0;

              span::after {
                display: none;
              }
            }

            &:hover .carousel-control-prev,
            &:hover .carousel-control-next {
              opacity: 1;
            }

            .carousel-indicators:not(.carousel-thumbnails) {
              a {
                border: none;
                border-radius: 100%;
                width: 10px;
                height: 10px;
              }
            }
          }

          .carousel-thumbnails {
            width: 100%;
            position: relative;
            margin: 0;

            .nx-events-detail-carousel-thumbnail {
              background-color: transparent;
              width: 200px;
              height: auto;
              opacity: 1;

              img {
                border-radius: 8px;
                overflow: hidden;
              }
            }
          }

          .carousel-captions {
            display: block;
            width: 100%;
            position: relative;
            margin: 0;

            .nx-events-detail-carousel-caption {
              background-color: #f4f5f9;
              border-radius: 4px;
              color: #2b2e38;
              font-size: 14px;
              font-weight: 400;
              line-height: 120%;
              text-align: left;
              display: none;
              padding: 10px 24px;
              margin: 16px 0;

              &.active {
                display: block;
              }

              p {
                font-size: 14px;
                font-weight: 400;
                line-height: 120%;
              }

              p:last-child {
                margin: 0;
              }
            }
          }
        }
      }

      @media (max-width: 767px) {
        .nx-container-about-carousel {
          max-width: none;
          padding-left: 0;
          padding-right: 0;

          .nx-events-detail-carousel {
            .nx-events-detail-carousel-wrapper {
              border-radius: 0;

              .carousel-inner {
                .carousel-item {
                  .nx-events-detail-carousel-item-wrapper {
                    .nx-events-detail-carousel-image {
                      border-radius: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.nx-attractions-detail-banner {
  background-color: #191b23;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(76vh - 156px);
}

@media (max-width: 1200px) {
  .nx-attractions-detail-banner {
    height: 376px;
  }
}

@media (max-width: 780px) {
  .nx-attractions-detail-banner {
    height: 506px;
  }
}

.nx-attractions-detail-header {
  text-align: left;
  max-width: 852px;
  position: relative;
  margin: 32px auto 0;

  .nx-attractions-detail-title {
    font-size: 38px;
    font-weight: 450;
    line-height: 150%;
    padding: 0;
    margin: 0 80px 0 0;
  }

  @media (max-width: 767px) {
    .nx-attractions-detail-title {
      font-size: 28px;
      line-height: 120%;
    }
  }

  .nx-attractions-detail-title-line {
    > span {
      background-color: #ff1044;
      display: block;
      width: 64px;
      height: 4px;
      position: absolute;
      top: 32px;
      right: 0;
    }
  }
}

.nx-attractions-detail-container {
  max-width: 852px;
  text-align: left;
  margin: 0 auto;

  .nx-attractions-detail-location {
    margin: 32px 0 0;
  }

  .nx-attractions-detail-categories {
    padding: 0;
    margin: 32px 0;

    > span {
      color: #191b23;
      font-size: 14px;
      font-weight: 330;
      line-height: 120%;
      text-transform: uppercase;
      padding: 7px 16px 7px 0;
    }
  }

  .nx-attractions-detail-content {
    font-size: 16px;
    font-weight: 450;
    line-height: 160%;
    padding: 0;
    margin: 32px 0;

    .legend {
      background-color: #f4f5f9;
      border-radius: 4px;
      color: #2b2e38;
      font-size: 14px;
      font-weight: 400;
      line-height: 120%;
      text-align: left;
      padding: 10px 24px;
      margin: 16px 0;
    }

    & img {
      width: 100%;
      height: auto;
      border-radius: 32px;
    }

    & a {
      color: #2b2e38;
      text-decoration: none;
      font-size: 16px;

      &::before {
        content: "";
        background-color: #2b2e38;
        mask-image: url("/vendors/images/icons/link-chain.svg");
        vertical-align: middle;
        display: inline-block;
        width: 24px;
        height: 25px;
        position: relative;
        top: -0.1em;
        transition: all 0.5s;
        margin: 0 3px 0 0;
      }
    }

    & a:hover {
      text-decoration: underline;
      color: #2b2e38;

      &::before {
        background-color: #2b2e38;
      }
    }

    & ul li {
      list-style: none;
    }

    & ul li::before {
      content: "\2022"; /* Unicode character for bullet point */
      color: #e9d942;
      margin-right: 10px;
    }

    & ol {
      list-style: none;
      counter-reset: my-counter; /* reset the counter to 0 */
    }

    & ol li::before {
      content: counter(my-counter);
      color: #e9d942;
      display: inline-block;
      width: 1em;
      margin-left: -1.5em;
      margin-right: 0.5em;
      text-align: right;
      counter-increment: my-counter;
    }

    & div {
      border-radius: 32px;
      overflow: hidden;
      width: 100%;
      max-width: 900px;
      position: relative;
      padding: 0 0 56.25%;
    }

    & div iframe {
      border: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .nx-attractions-detail-images {
    text-align: center;
    margin: 32px 0;

    img {
      border-radius: 32px;
      width: 100%;
      max-width: 900px;
      margin: 0 auto;
      height: auto;
    }

    .caption {
      background-color: #f4f5f9;
      border-radius: 4px;
      color: #2b2e38;
      font-size: 14px;
      font-weight: 400;
      line-height: 120%;
      text-align: left;
      padding: 10px 24px;
      margin: 16px 0;

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 120%;
      }

      p:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .nx-attractions-detail-carousel {
    margin: 40px 0;

    .nx-attractions-detail-carousel-wrapper {
      border-radius: 32px;
      text-align: center;
      overflow: hidden;
      position: relative;
      margin: 0 0 16px;

      .carousel-inner {
        aspect-ratio: 852/560;

        .carousel-item {
          max-width: 100%;
          max-height: 100%;

          .nx-attractions-detail-carousel-item-wrapper {
            text-align: center;
            max-width: 100%;
            max-height: 100%;

            .nx-attractions-detail-carousel-image {
              max-width: 100%;
              max-height: 100%;
              object-fit: contain;
              object-position: center;
            }
          }
        }
      }

      .carousel-control-prev,
      .carousel-control-next {
        opacity: 0;

        span::after {
          display: none;
        }
      }

      &:hover .carousel-control-prev,
      &:hover .carousel-control-next {
        opacity: 1;
      }

      .carousel-indicators:not(.carousel-thumbnails) {
        a {
          border: none;
          border-radius: 100%;
          width: 10px;
          height: 10px;
        }
      }
    }

    .carousel-thumbnails {
      width: 100%;
      position: relative;
      margin: 0;

      .nx-attractions-detail-carousel-thumbnail {
        border-radius: 8px;
        width: 200px;
        height: auto;
        opacity: 1;
      }
    }

    .carousel-captions {
      display: block;
      width: 100%;
      position: relative;
      margin: 0;

      .nx-attractions-detail-carousel-caption {
        background-color: #f4f5f9;
        border-radius: 4px;
        color: #2b2e38;
        font-size: 14px;
        font-weight: 400;
        line-height: 120%;
        text-align: left;
        display: none;
        padding: 10px 24px;
        margin: 16px 0;

        &.active {
          display: block;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 120%;
        }

        p:last-child {
          margin: 0;
        }
      }
    }
  }
}

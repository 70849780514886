.breadcrumbs {
  margin-top: 100px;
  background-color: white;
  box-shadow: rgba(0 0 0 / 25%) 0 0 5px -1px, rgba(0 0 0 / 3%) 0 1px 3px -1px;
  z-index: 10;

  .container {
    display: flex;
    margin: 0 auto;
    justify-content: left;
    align-items: center;
    height: inherit;
    min-height: 56px;
    gap: 12px;
    padding: 12px 16px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-flex;
    gap: 16px;

    li {
      display: flex;
      align-items: center;
      gap: 16px;
      text-transform: uppercase;
      text-align: left;

      a {
        text-decoration: none;
        color: #6c6e79;

        &:hover {
          text-decoration: underline;
        }
      }

      &:last-child {
        a {
          color: black;
        }
      }

      &:not(:last-child)::after {
        content: " ";
        background-image: url("/vendors/images/icons/arrow-right-2-black.png");
        width: 6px;
        height: 11px;
        display: block;
        background-size: cover;
        background-position: center;
      }
    }
  }

  @media (min-width: 992px) {
    .back-link-arrow {
      display: none;
    }
  }

  @media (max-width: 991px) {
    ul {
      li:not(:last-child) {
        display: none;
      }
    }
  }
}

.page-wrapper-events {
  .breadcrumbs {
    position: fixed;
    width: 100%;
    top: 0;
  }
}

.event-navigation {
  background-color: #191b23;
  height: 72px;
  width: 100%;

  .container {
    display: flex;
    height: inherit;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 12px;
    margin: 0 auto;
  }

  .nx-events-nav-topbar-left {
    .nx-events-btn-notifications {
      background-color: transparent;
      border: 0;
      color: #fff;
      outline: none;
      display: flex;
      flex-direction: row;
      align-items: center;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }

      &:not(:disabled):hover {
        opacity: 0.7;
      }

      &:not(:disabled):active {
        opacity: 0.5;
      }

      &.nx-enable-notifications {
        .nx-enable-notifications {
          display: block;
        }

        .nx-disable-notifications {
          display: none;
        }
      }

      &.nx-disable-notifications {
        .nx-enable-notifications {
          display: none;
        }

        .nx-disable-notifications {
          display: block;
        }
      }

      > span {
        font-size: 12px;
        font-weight: 330;
        line-height: 120%;
        text-transform: uppercase;
        display: inline-block;
        margin: 0 0 0 6px;
      }

      @media (max-width: 767px) {
        > span {
          display: none !important;
        }
      }
    }
  }

  .nx-events-nav-topbar-right {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .date-tag {
    font-size: 14px;
    padding: 8px;
    border: 1px solid white;
    border-radius: 4px;
    color: white;
    height: 56px;
    margin-right: auto;
  }

  .price-tag {
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
    font-family: Inter, sans-serif;
    margin: 0 12px 0 0;
  }

  .add-to-cart {
    background-color: $yellow;
    border: 1px solid black;
    border-radius: 25px;
    color: #000;
    text-decoration: none;
    display: inline-flex;
    gap: 8px 10px;
    padding: 5px 10px;
    margin: 0;

    &:hover {
      background-color: $yellow-dark;
    }
  }
}

.page-wrapper-event {
  .event-navigation {
    position: fixed;
    width: 100%;
  }
}

.nx-page-myreservations {
  margin: 0 auto;

  .nx-container-main {
    max-width: 600px;
    margin: 0 auto;
  }

  .nx-myreservations-external-note {
    background-color: #fffde5;
    border: 1px solid #fff9c1;
    border-radius: 32px;
    text-align: left;
    max-width: 516px;
    position: relative;
    padding: 24px 24px 24px 56px;
    margin: 0 auto;

    .nx-myreservations-external-note-icon {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 24px;
      left: 24px;
    }

    p {
      font-size: 12px;
      font-weight: 450;
      line-height: 200%;
      padding: 0;
      margin: 0;
    }
  }

  .accordion-body {
    max-width: 516px;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .accordion-button {
    font-size: 24px;
    font-weight: 450;
    line-height: 120%;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 516px;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .accordion-button:focus {
    border: none;
    box-shadow: none;
  }

  .accordion-button:not(.collapsed) {
    background-color: transparent;
    color: #000;
    box-shadow: none;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
  }

  .nx-accordion-header-icon {
    font-size: 0;
    line-height: 0;
    margin: 0 16px 4px 0;
  }

  .nx-accordion-header-title {
    word-break: break-word;
  }

  .nx-event-tickets {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .nx-event-btn a {
      border: 1px solid #c4c7cf;
      border-radius: 32px;
      color: #191b23;
      font-size: 16px;
      font-weight: 120%;
      line-height: 120%;
      text-decoration: none;
      text-transform: none;
      transition: background-color 0.3s, border-color 0.3s, color 0.3s, opacity 0.3s;
      padding: 6px 16px;
    }

    .nx-event-btn a:hover {
      background-color: #191b23;
      border: 1px solid #191b23;
      color: #fff;
    }

    .nx-event-btn a:active {
      opacity: 0.5;
    }
  }

  .nx-container-large {
    margin: 0 0 100px;

    .accordion-header {
      background-color: #191b23;
      color: #fff;
    }

    .accordion-button {
      background-color: #191b23;
      color: #fff;
      display: flex;
      flex-direction: row;
      align-items: center;
      max-width: calc(516px + ((var(--bs-gutter-x) * 0.5) * 2));
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      margin: 0 auto 32px;
    }

    .accordion-collapse {
      max-width: 576px;
      margin: 0 auto;
    }

    .accordion-button::after {
      filter: invert(1);
    }

    .accordion-body {
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      padding-right: calc(var(--bs-gutter-x) * 0.5);
    }
  }

  .card {
    background-color: #f4f5f9;
    border-color: #e0e1e9;
    border-radius: 32px;
    box-shadow: none;
    text-align: left;
    padding: 16px 24px;
    margin: 0 0 24px;

    &.pastevent {
      background-color: #fff;
    }

    .nx-event-title h3 {
      font-size: 20px;
      font-weight: 450;
      line-height: 120%;
      padding: 0;
      margin: 0 0 16px;
    }

    .nx-event-description {
      font-size: 16px;
      font-weight: 450;
      line-height: 120%;

      a.nx-event-link {
        color: #000;
        text-decoration: none;
        display: block;

        &:hover {
          text-decoration: underline;
        }
      }

      p:last-child {
        margin: 0;
      }
    }

    .nx-event-date-time {
      font-size: 14px;
      font-weight: 450;
      line-height: 100%;
      text-transform: uppercase;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 12px 0 0;

      span {
        margin: 1px 0 0 6px;
      }
    }

    hr {
      margin: 24px 0;
    }
  }
}

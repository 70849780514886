.nx-page-login,
.nx-page-reset-password {
  max-width: 428px;
  padding: 200px 24px 100px;
  margin: 0 auto;

  button[type="submit"] {
    background-color: #191b23;
    border-radius: 32px;
    display: block;
    padding: 12px 40px;
    margin: 0 auto;

    &.loading {
      display: flex;
      justify-content: center;
      flex-direction: revert;
      align-items: center;
    }
  }

  button[type="submit"]:hover {
    background-color: #2b2e38;
  }

  button[type="submit"]:active {
    opacity: 0.7;
  }

  .card {
    border-radius: 32px;
    box-shadow: none;
    text-align: left;

    .nx-reset-password-form {
      padding: 14px;

      .nx-reset-password-error {
        color: #f00;
        font-size: 14px;
        line-height: 120%;
        margin: 24px 0 0;
      }
    }
  }

  .accordion {
    text-align: left;
    padding-bottom: 12px;

    .help-text {
      color: #484a54;
      font-size: 12px;
      width: 100%;
      margin-top: 0.25rem;
    }

    .invalid-feedback {
      position: relative;
    }

    .invalid-error {
      color: #dc3545;
      font-size: 0.875em;
      width: 100%;
      margin-top: 0.25rem;
    }

    .accordion-item {
      overflow: hidden;
    }

    .accordion-item:first-of-type {
      border-top-left-radius: 32px;
      border-top-right-radius: 32px;
    }

    .accordion-item:last-of-type {
      border-bottom-right-radius: 32px;
      border-bottom-left-radius: 32px;
    }

    .accordion-button:not(.collapsed) {
      color: #000;
    }

    .accordion-button:not(.collapsed)::after {
      background-image: var(--bs-accordion-btn-icon);
    }

    .accordion-item:first-of-type .accordion-button {
      background-color: #fff;
      border-top-left-radius: 32px;
      border-top-right-radius: 32px;
      box-shadow: none;
    }
  }

  .nx-accordion-header-icon {
    background-color: #191b23;
    border-radius: 100%;
    color: #fff;
    line-height: 32px;
    text-align: center;
    display: inline-block;
    width: 32px;
    height: 32px;
    margin: 0 12px 0 0;
  }

  label[for="id_is_accept_termsandconditions"] {
    a {
      color: inherit;
      text-decoration: none;
      text-transform: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.sticky-message-wrapper {
  position: fixed;
  top: 96px;
  width: 100%;
  padding: 16px;
  z-index: 999;

  .sticky-message {
    margin: 0 auto;
    min-height: 75px;
    width: 100%;
    display: flex;
    border-top: 4px solid #191b23;
    background-color: white;
    border-radius: 4px;
    box-shadow: rgba(99 99 99 / 20%) 0 2px 8px 0;
    padding: 20px;
    justify-content: space-between;

    .nx-icon {
      img {
        display: none;
      }
    }

    &.success {
      border-color: #327864;

      .nx-icon {
        .nx-icon-success {
          display: block;
        }
      }
    }

    &.error {
      border-color: #af0228;

      .nx-icon {
        .nx-icon-error {
          display: block;
        }
      }
    }

    &.warning {
      border-color: #e9d942;

      .nx-icon {
        .nx-icon-warning {
          display: block;
        }
      }
    }

    .close-btn {
      background-color: transparent;
      border: 0;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .content {
      display: flex;
      gap: 20px;
      align-items: center;

      p {
        margin: 0;
      }

      ul {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
        gap: 16px;

        li {
          a {
            color: #bdad12;
            text-decoration: none;
            text-transform: uppercase;
          }
        }
      }

      .msg {
        text-align: left;

        a {
          color: $yellow-dark;
          cursor: pointer;

          &:hover {
            color: #bdad12;
            text-decoration: underline;
          }
        }
      }
    }

    .nx-dialog-sticky-btn-close {
      background-color: transparent;
      border: 0;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.footer-main {
  background-color: #191b23;
  color: #fff;
  display: flex;
  min-height: 300px;
  padding: 50px 0;

  .bottom-section {
    display: flex;
    padding-top: 70px;

    ul {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
      gap: 25px;

      li {
        a {
          color: #fff;
          font-size: 12px;
          font-weight: 450;
          line-height: 120%;
          text-decoration: none;
          text-transform: uppercase;

          &:hover {
            text-decoration: underline;
          }
        }

        .nx-copyright {
          color: #fff;
          font-size: 12px;
          font-weight: 450;
          line-height: 120%;
          text-transform: uppercase;
        }
      }
    }

    .nx-copynixa {
      color: #fff;
      text-align: left;

      .nx-copynixa-link {
        color: #fff;
        font-size: 11px;
        font-weight: 450;
        line-height: 120%;
        letter-spacing: 1px;
        text-decoration: none;
        text-transform: none;
        display: inline-block;
        padding: 12px 0 0;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .nx-footer-ivanhoe-cambridge {
      .nx-ivanhoe-cambridge-logo {
        display: inline-block;

        &:hover {
          opacity: 0.6;
        }

        .nx-ivanhoe-cambridge-logo-img {
          width: 150px;
          height: auto;
          filter: invert(1);
        }
      }
    }

    @media (max-width: 767px) {
      .nx-footer-ivanhoe-cambridge {
        text-align: left;
        align-self: flex-start !important;
        margin-top: 32px;
      }
    }
  }

  .top-section {
    display: flex;
    justify-content: space-between;

    .ts-left {
      img {
        max-width: 221px;
      }
    }

    .ts-right {
      .social-links {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        gap: 16px;
        justify-content: flex-end;

        li {
          a {
            text-decoration: none;
          }
        }
      }

      .news-input-wrapper {
        color: white;
        margin-top: 16px;

        label {
          font-size: 14px;
          text-transform: uppercase;
          color: white;
          margin-bottom: 8px;
        }

        .input-group {
          border-bottom: 1px solid #fff;
          justify-content: space-between;

          input {
            background-color: transparent;
            color: #fff;
            border: 0;
            padding: 10px 4px;

            &::placeholder {
              color: white;
              font-size: 14px;
            }
          }

          button {
            border: 0;
            width: 16px;
            background-color: transparent;
          }
        }

        #mce-responses {
          max-width: 255px;
        }

        .mce-law25-unsubinfo {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: center;
          gap: 24px;
          padding: 12px 0;

          label {
            font-size: 12px;
            line-height: 120%;
            text-align: left;
            text-transform: none;
            cursor: pointer;
          }
        }

        .input-group:has(input[class$="error"]) {
          border-bottom-color: #f00;
        }

        .mce-error-response {
          font-size: 12px;
          line-height: 120%;
          text-align: left;
          padding: 12px 0;
        }
      }
    }
  }

  @media (max-width: $mq-sm) {
    flex-direction: column;

    .bottom-section {
      padding-top: 22px;

      ul {
        gap: 8px;
        flex-direction: column;
        align-items: baseline;
      }
    }

    .top-section {
      flex-direction: column;
      align-items: baseline;

      .ts-right {
        .social-links {
          justify-content: flex-start;
        }
      }

      .ts-left {
        margin-bottom: 25px;
      }
    }
  }
}

.nx-events-detail-banner {
  background-color: #191b23;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(76vh);
}

@media (max-width: 1200px) {
  .nx-events-detail-banner {
    height: 580px;
  }
}

@media (max-width: 780px) {
  .nx-events-detail-banner {
    height: 506px;
  }
}

@media (max-width: 600px) {
  .nx-events-detail-banner {
    height: 394px;
  }
}

.content-header {
  width: 100%;
  max-width: 832px;
  display: inline-flex;
  gap: 20px;
  margin-top: 32px;

  .circle-art {
    margin-top: 10px;

    span {
      border-radius: 50%;
      display: block;
      width: 30px;
      height: 30px;
      background-color: #327864;
    }
  }

  .line-decor {
    padding-top: 10px;

    span {
      height: 2px;
      width: 100%;
      min-width: 100px;
      background-color: black;
      display: block;
      margin-top: 23px;
    }
  }

  h1 {
    font-size: 38px;
    font-weight: 450;
    line-height: 150%;
    text-align: left;
    margin-right: 32px;
  }

  @media (max-width: 599px) {
    h1 {
      font-size: 24px;
      font-weight: 450;
      line-height: 150%;
    }
  }
}

.video-container {
  border-radius: 32px;
  overflow: hidden;
  width: 100%;
  max-width: 900px;
  position: relative;
  padding: 0 0 56.25%;

  iframe {
    border: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.min-container {
  max-width: 832px;
  text-align: left;
  padding: 0 48px;
  margin: auto;

  .nx-events-detail-description {
    border-top: 1px solid #c4c7cf;
    border-bottom: 1px solid #c4c7cf;
    font-size: 16px;
    font-weight: 450;
    line-height: 150%;
    padding: calc(16px + 1rem) 0 16px;
    margin: 12px 0;

    .legend {
      background-color: #f4f5f9;
      border-radius: 4px;
      color: #2b2e38;
      font-size: 14px;
      font-weight: 400;
      line-height: 120%;
      text-align: left;
      padding: 10px 24px;
      margin: 16px 0;
    }

    & img {
      width: 100%;
      height: auto;
      border-radius: 32px;
    }

    & a {
      color: #2b2e38;
      text-decoration: none;
      font-size: 16px;

      &::before {
        content: "";
        background-color: #2b2e38;
        mask-image: url("/vendors/images/icons/link-chain.svg");
        vertical-align: middle;
        display: inline-block;
        width: 24px;
        height: 25px;
        transition: all 0.5s;
        margin: 0 3px 3px 0;
      }
    }

    & a:hover {
      text-decoration: underline;
      color: #2b2e38;

      &::before {
        background-color: #2b2e38;
      }
    }

    & ul li {
      list-style: none;
    }

    & ul li::before {
      content: "\2022"; /* Unicode character for bullet point */
      color: #e9d942;
      margin-right: 10px;
    }

    & ol {
      list-style: none;
      counter-reset: my-counter; /* reset the counter to 0 */
    }

    & ol li::before {
      content: counter(my-counter);
      color: #e9d942;
      display: inline-block;
      width: 1em;
      margin-left: -1.5em;
      margin-right: 0.5em;
      text-align: right;
      counter-increment: my-counter;
    }

    & div {
      border-radius: 32px;
      overflow: hidden;
      width: 100%;
      max-width: 900px;
      position: relative;
      padding: 0 0 56.25%;
    }

    & div iframe {
      border: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    & i {
      font-style: italic;
    }
  }

  @media (max-width: 599px) {
    .nx-events-detail-description {
      font-size: 12px;
      font-weight: 450;
      line-height: 200%;
    }
  }

  .nx-events-detail-categories {
    .small-desc {
      font-size: 14px;
      font-weight: 330;
      line-height: 120%;
      text-transform: uppercase;
      display: inline-block;
      margin: 12px 24px 12px 0;
    }
  }

  .nx-events-detail-location-price {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .nx-events-detail-location {
      border: 1px solid #000;
      border-radius: 25px;
      font-size: 14px;
      line-height: 120%;
      height: 32px;
      display: inline-flex;
      gap: 4px 10px;
      padding: 6px 12px;
      margin: 12px 6px 0 0;

      > span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        max-width: 50vw;
      }
    }

    @media (max-width: 300px) {
      .nx-events-detail-location {
        font-size: 14px;
        line-height: 120%;
      }
    }

    .nx-events-detail-price {
      border: 1px solid #c4c7cf;
      border-radius: 25px;
      font-family: Inter, sans-serif;
      display: inline-flex;
      gap: 4px 10px;
      padding: 5px 12px;
      margin: 12px 0 0;
      height: 32px;
      font-size: 14px;
    }
  }

  @media (max-width: 400px) {
    .nx-events-detail-location-price {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .nx-events-detail-eventdates {
    padding: 32px 0 0;
    margin: 12px 0;

    .nx-events-detail-eventdates-title {
      font-size: 18px;
      font-weight: 450;
      line-height: 120%;
      text-transform: uppercase;
      margin: 0 0 24px;
    }

    .nx-events-detail-date {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 12px 0;

      .nx-events-detail-date-time {
        font-size: 18px;
        font-weight: 450;
        line-height: 120%;
        text-transform: uppercase;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 6px 0 0;

        span {
          margin: 0 0 0 6px;
        }
      }

      @media (max-width: 767px) {
        .nx-events-detail-date-time {
          font-size: 14px;
          line-height: 120%;
        }
      }

      .nx-events-detail-ticket-add2cart {
        background-color: #191a24;
        border: 1px solid #191a24;
        border-radius: 32px;
        color: #fff;
        font-size: 16px;
        font-weight: 450;
        line-height: 120%;
        text-decoration: none;
        white-space: nowrap;
        outline: none;
        padding: 10px 16px;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &:disabled {
          opacity: 0.6;
        }

        &:not(:disabled):hover {
          background-color: #2b2e38;
          border-color: #2b2e38;
        }

        &:not(:disabled):active {
          background-color: #2b2e38;
          border-color: #2b2e38;
          opacity: 0.7;
        }
      }
    }

    @media (max-width: 599px) {
      .nx-events-detail-date {
        flex-direction: column;
        align-items: flex-start;

        .nx-events-detail-ticket-add2cart {
          font-size: 14px;
          line-height: 120%;
          margin: 4px 0;
        }
      }
    }

    .nx-events-detail-eventdates-seemore {
      padding: 12px 0;

      .nx-events-detail-eventdates-seemore-wrapper {
        display: none;
        position: relative;
      }

      .nx-events-detail-eventdates-seemore-content {
        border-top: 1px solid #c4c7cf;
        padding: 12px 0;
      }

      .nx-events-detail-seemore-btn {
        background-color: #fff;
        border: 1px solid #c4c7cf;
        border-radius: 32px;
        color: #484a54;
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-weight: 450;
        line-height: 120%;
        outline: none;
        padding: 10px 16px;
        margin: 0;
      }

      .nx-seemore-btn-closed {
        display: block;
      }

      .nx-seemore-btn-opened {
        display: none;
      }

      &.nx-open .nx-seemore-btn-closed {
        display: none;
      }

      &.nx-open .nx-seemore-btn-opened {
        display: block;
      }
    }
  }

  .img-container {
    text-align: center;
    margin: 40px 0;

    img {
      border-radius: 32px;
      width: auto;
      max-width: 100%;
      margin: 0 auto;
    }

    .desc {
      background-color: #f4f5f9;
      border-radius: 4px;
      color: #2b2e38;
      font-size: 14px;
      font-weight: 400;
      line-height: 120%;
      text-align: left;
      padding: 10px 24px;
      margin: 16px 0;

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 120%;
      }

      p:last-child {
        margin: 0;
      }
    }
  }

  .nx-events-detail-carousel {
    margin: 40px 0;

    .nx-events-detail-carousel-wrapper {
      border-radius: 32px;
      text-align: center;
      overflow: hidden;
      position: relative;
      margin: 0 0 16px;

      .carousel-inner {
        aspect-ratio: 852/560;

        .carousel-item {
          max-width: 100%;
          max-height: 100%;

          .nx-events-detail-carousel-item-wrapper {
            text-align: center;
            max-width: 100%;
            max-height: 100%;

            .nx-events-detail-carousel-image {
              max-width: 100%;
              max-height: 100%;
              object-fit: contain;
              object-position: center;
            }
          }
        }
      }

      .carousel-control-prev,
      .carousel-control-next {
        opacity: 0;

        span::after {
          display: none;
        }
      }

      &:hover .carousel-control-prev,
      &:hover .carousel-control-next {
        opacity: 1;
      }

      .carousel-indicators:not(.carousel-thumbnails) {
        a {
          border: none;
          border-radius: 100%;
          width: 10px;
          height: 10px;
        }
      }
    }

    .carousel-thumbnails {
      width: 100%;
      position: relative;
      margin: 0;

      .nx-events-detail-carousel-thumbnail {
        background-color: transparent;
        width: 200px;
        height: auto;
        opacity: 1;

        > * {
          border-radius: 8px;
          overflow: hidden;
        }
      }
    }

    .carousel-captions {
      display: block;
      width: 100%;
      position: relative;
      margin: 0;

      .nx-events-detail-carousel-caption {
        background-color: #f4f5f9;
        border-radius: 4px;
        color: #2b2e38;
        font-size: 14px;
        font-weight: 400;
        line-height: 120%;
        text-align: left;
        display: none;
        padding: 10px 24px;
        margin: 16px 0;

        &.active {
          display: block;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 120%;
        }

        p:last-child {
          margin: 0;
        }
      }
    }
  }
}

.nx-event-sticky {
  display: none;
  opacity: 0;

  .nx-event-sticky-btn-close {
    background-color: transparent;
    border: 0;

    img {
      width: 16px;
      height: 16px;
    }
  }
}

@media (max-width: 599px) {
  .min-container {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.locations-container {
  justify-content: flex-start !important;
}

@font-face {
	font-family: "Inter";
	src: url("../../vendors/inter/Inter-VariableFont.ttf") format("truetype-variations");
	font-weight: 100 1000;
	font-stretch: 25% 151%;
}
@font-face {
	font-family: "Dazzed TRIAL";
	src: url("../../vendors/dazzed_trial/DazzedTRIALVF.ttf") format("truetype-variations");
	font-weight: 100 1000;
	font-stretch: 25% 151%;
}

/* ===--- Bootstrap layout grid
// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { ... }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { ... }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { ... }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { ... }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { ... }
---=== */

:root {
    --bs-font-sans-serif: "Dazzed TRIAL", sans-serif;
    --mdb-body-font-family: var(--bs-font-sans-serif);

    --nx-bg-color: #F4F5F9;
    --nx-font-color: #191b23;
    --nx-grey: #6C6E79;
}

html {
    background-color: #191b23;
}

body {
    background-color: var(--nx-bg-color);
    color: var(--nx-font-color);
    text-align: center;
    padding: 0;
    margin: 0;
}

i {
    font-style: inherit;
}

/* ===--- HEADINGS ---=== */
h1 {
    font-family: "Dazzed TRIAL", sans-serif;
    font-size: 34px;
    font-weight: 450;
    line-height: 150%;
    padding: 0;
    margin: 0;
}

/* ===--- BUTTONS ---=== */

.btn {
  font-family: "Dazzed TRIAL", sans-serif;
  font-size: 16px;
  font-weight: 450;
  text-transform: none;
  border-radius: 0;
  padding: 12px 16px;
  border-radius: 32px;
}
.btn-primary {
    --bs-btn-bg: var(--nx-grey);
    --bs-btn-color: #fff;
    --bs-btn-border-color: var(--nx-grey);
    --bs-btn-box-shadow: none;
    --bs-btn-hover-bg: var(--nx-grey);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-border-color: var(--nx-grey);
    --bs-btn-focus-bg: var(--nx-grey);
    --bs-btn-focus-color: #fff;
    --bs-btn-focus-border-color: var(--nx-grey);
    --bs-btn-active-bg: var(--nx-grey);
    --bs-btn-active-color: #fff;
    --bs-btn-active-border-color: var(--nx-grey);
}
.btn-outline-primary {
    --bs-btn-bg: transparent;
    --bs-btn-color: var(--nx-grey);
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-color: var(--nx-grey);
    --bs-btn-focus-bg: transparent;
    --bs-btn-focus-color: var(--nx-grey);
    --bs-btn-active-bg: transparent;
    --bs-btn-active-color: var(--nx-grey);
    border-color: var(--nx-grey);
}
.form-control:focus {
    border-color: var(--nx-grey);
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
    margin-bottom: 4px;
}
.form-label {
    color: #484A54;
}

/* ===--- NAVBAR ---=== */
.nx-navbar {
    box-shadow: 0px 0px 1px rgba(25, 27, 35, 0.16), 0px 1px 2px rgba(25, 27, 35, 0.12);
    color: var(--nx-font-color);
    font-size: 18px;
    line-height: 120%;
    padding-top: 12px;
    padding-bottom: 12px;
}
#nx-navbar-collapse:not(.show) > ul {
    justify-content: flex-end;
}

.nx-navbar.navbar-light .navbar-nav .nav-link {
    color: var(--nx-font-color);
    text-transform: uppercase;
}

/* ===--- FORMS ---=== */
input.form-control,
input.form-control:focus {
    background: transparent;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    box-shadow: none;
    outline: 0;
    padding: 4px 0;
}

/* ===--- HEADER ---=== */
.nx-subtitle-text {
    font-size: 16px;
    font-weight: 330;
    line-height: 120%;
    padding: 24px 4px;
}

/* ===--- FOOTER ---=== */
#nx-footer {
    background-color: #000;
}

/* ===--- PAGE: login ---=== */
#nx-form-accordion {
    --mdb-accordion-border-color: transparent;
    --mdb-accordion-border-width: 0;
    --mdb-accordion-border-radius: 0;
    border: none;
    border-radius: 32px;
    box-shadow: 0px 0px 1px rgba(25, 27, 35, 0.16), 0px 0 2px rgba(25, 27, 35, 0.12);
    max-width: 516px;
    overflow: hidden;
    padding: 0;
    margin: 0 auto;
}
#nx-form-accordion .nx-accordion-header-icon {
    background-color: #E0E1E9;
    color: #000;
    border-radius: 100%;
    line-height: 32px;
    text-align: center;
    width: 32px;
    height: 32px;
    margin: 0 12px 0 0;
}
#nx-form-accordion .accordion-item:first-of-type,
#nx-form-accordion .accordion-item:first-of-type .accordion-button,
#nx-form-accordion .accordion-button:not(.collapsed):focus {
    border: none;
    border-radius: 0!important;
    box-shadow: none;
}
#nx-form-accordion .accordion-item:last-of-type .accordion-collapse {
    border: none;
    border-radius: 0!important;
}
#nx-form-accordion .accordion-item .accordion-button,
#nx-form-accordion .accordion-item .accordion-button:not(.collapsed) {
    --bs-accordion-active-bg: transparent;
    color: #000;
}
#nx-form-accordion form {
    text-align: left;
    margin: 0 auto;
}
#nx-form-accordion label {
    font-size: 16px;
    font-weight: 450;
    padding: 0;
    margin: 0;
}
.nx-link-reset-password {
    font-family: "Inter", sans-serif;
    color: var(--nx-grey);
    font-size: 12px;
    font-weight: 400;
    line-height: 120%;
    text-align: left;
    text-decoration: underline;
}

#nx-form-accordion .invalid-feedback {
    position: relative;
    padding: 0;
    margin: 0;
}

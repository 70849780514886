$framboise: #ff1044;
$clementine: #ffaa23;
$bleu-vert: #327864;
$yellow: #fff7ac;
$yellow-dark: #e9d942;

.framboise {
  color: $framboise;
}

.clementine {
  color: $clementine;
}

.bleu-vert {
  color: $bleu-vert;
}

.yellow {
  color: $yellow;
}

.framboise-bg {
  background-color: $framboise;
}

.clementine-bg {
  background-color: $clementine;
}

.bleu-vert-bg {
  background-color: $bleu-vert;
}

.yellow-bg {
  background-color: $yellow;
}

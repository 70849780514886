.nx-attractionslist {
  padding: 50px 0 0;

  #nx-attractions-list-items {
    .nx-attraction-item-withimg .nx-attraction-item-description,
    .nx-attraction-item-bgyellow .nx-attraction-item-description {
      & a {
        color: #191b23;
        text-decoration: none;
        font-size: 16px;

        &::before {
          content: "";
          background-color: #191b23;
          mask-image: url("/vendors/images/icons/link-chain.svg");
          vertical-align: middle;
          display: inline-block;
          width: 24px;
          height: 25px;
          transition: all 0.5s;
          margin: 0 4px 2px 0;
        }
      }
    }

    .nx-attraction-item-bgblack .nx-attraction-item-description {
      & a {
        color: #fff;
        text-decoration: none;
        font-size: 16px;

        &::before {
          content: "";
          background-color: #fff;
          mask-image: url("/vendors/images/icons/link-chain.svg");
          vertical-align: middle;
          display: inline-block;
          width: 24px;
          height: 25px;
          transition: all 0.5s;
          margin: 0 4px 2px 0;
        }
      }
    }

    .nx-attraction-item-withimg .nx-attraction-item-description a:hover,
    .nx-attraction-item-bgyellow .nx-attraction-item-description a:hover {
      text-decoration: underline;
      color: #191b23;

      &::before {
        background-color: #191b23;
      }
    }

    .nx-attraction-item-bgblack .nx-attraction-item-description a:hover {
      text-decoration: underline;
      color: #fff;

      &::before {
        background-color: #fff;
      }
    }

    .nx-attraction-item-withimg {
      cursor: pointer;

      .events-list {
        padding: 32px 0;

        .title-wrapper {
          font-size: 24px;
          line-height: 150%;

          .nx-attraction-item-link {
            color: #000;
            text-transform: none;

            h3 {
              font-size: 24px;
              font-weight: 450;
              line-height: 150%;
            }
          }

          .line-side {
            max-width: 110px;
            position: relative;
            top: 0.5em;
            margin: 0 0 0 auto;
          }
        }

        .nx-attraction-item-description {
          font-size: 16px;
          line-height: 150%;
        }

        .nx-attraction-item-categories {
          font-size: 14px;
          line-height: 120%;
          text-transform: uppercase;
        }
      }

      &:hover {
        > div {
          opacity: 0.8;
        }
      }
    }

    .nx-attraction-item-bgyellow {
      cursor: pointer;

      .container {
        .title-wrapper {
          .nx-attraction-item-link {
            color: #000;
            text-transform: none;

            h3 {
              font-size: 38px;
              font-weight: 450;
              line-height: 150%;
            }
          }
        }

        @media (max-width: 992px) {
          .title-wrapper {
            margin-top: 0;
          }
        }

        .nx-attraction-item-description {
          font-size: 16px;
          line-height: 150%;
        }

        .nx-attraction-item-categories {
          font-size: 14px;
          line-height: 120%;
          text-transform: uppercase;
        }
      }

      &:hover {
        > div {
          opacity: 0.8;
        }
      }
    }

    .nx-attraction-item-bgblack {
      color: #fff;
      cursor: pointer;

      .container {
        .bs-wrapper {
          font-size: 24px;
          line-height: 150%;

          .nx-attraction-item-link {
            color: #fff;
            text-transform: none;

            h3 {
              color: #fff;
              font-size: 24px;
              font-weight: 450;
              line-height: 150%;
            }
          }

          .line-side {
            max-width: 110px;
            position: relative;
            top: 0.5em;
            margin: 0 0 0 auto;
          }
        }

        .nx-attraction-item-description {
          font-size: 16px;
          font-weight: 350;
          line-height: 150%;

          p {
            font-size: 16px;
            line-height: 150%;
          }
        }

        .nx-attraction-item-categories {
          font-size: 14px;
          line-height: 120%;
          text-transform: uppercase;
        }
      }

      &:hover {
        > div {
          opacity: 0.8;
        }
      }
    }

    .nx-attraction-item-withimg .events-list .title-wrapper .nx-attraction-item-link,
    .nx-attraction-item-bgyellow .container .title-wrapper .nx-attraction-item-link,
    .nx-attraction-item-bgblack .container .bs-wrapper .nx-attraction-item-link {
      text-decoration: none;
      text-transform: none;
    }

    .nx-attraction-item-withimg .events-list .title-wrapper .nx-attraction-item-link:hover,
    .nx-attraction-item-bgyellow .container .title-wrapper .nx-attraction-item-link:hover,
    .nx-attraction-item-bgblack .container .bs-wrapper .nx-attraction-item-link:hover {
      text-decoration: underline;
    }

    .nx-event-item-none {
      padding: 64px 0;
    }
  }

  #nx-attractions-list-loading {
    padding: 32px 0 64px;
  }

  #nx-attractions-list-models {
    display: none;
  }
}

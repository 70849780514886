.nx-eventslist {
  .nx-event-item-left,
  .nx-event-item-right,
  .nx-event-item-fullbottom,
  .nx-event-item-doublebottom-left,
  .nx-event-item-doublebottom-right {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .nx-event-item-link {
    color: #000;
    text-decoration: none;
    text-transform: none;
    display: block;

    &:hover {
      text-decoration: underline;
    }
  }

  .nx-tabs-event-locations {
    li {
      transition: backgroundcolor 0.3s;
    }
  }

  .events-list {
    > .row {
      margin-bottom: 64px;
    }
  }

  #nx-events-list-models {
    display: none;
  }
}

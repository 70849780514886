.header-main {
  background-color: #191b23;
  background-size: cover;
  background-position: center;
  color: white;
  text-align: left;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: calc(76vh - 100px);
  position: relative;
  padding: 48px 0;
  margin-top: 100px;

  .fond {
    object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    min-height: calc((var(--vh, 1vh) * 100) - 100px);
    z-index: 0;
  }

  .container {
    z-index: 1;
  }

  .nx-header-title {
    position: relative;

    h2 {
      font-style: normal;
      font-weight: 450;
      font-size: 96px;
      line-height: 106px;
      letter-spacing: -0.05em;
    }

    p {
      font-size: 38px;
      display: flex;
      align-items: center;
      gap: 16px;
    }

    p:last-of-type::after {
      content: "";
      background-color: #fff7ac;
      border-radius: 50%;
      display: block;
      width: 30px;
      height: 30px;
    }

    @media (min-width: 801px) and (max-height: 660px) {
      h2 {
        font-size: 56px;
        line-height: 120%;
      }

      p {
        font-size: 24px;
      }
    }

    @media (min-width: 801px) and (max-height: 500px) {
      h2 {
        font-size: 36px;
        line-height: 120%;
      }

      p {
        font-size: 16px;
      }
    }

    @media (max-width: 800px) {
      align-items: center;

      h2 {
        font-size: 65px;
        line-height: initial;
      }

      p {
        font-size: 30px;
      }
    }

    @media (max-width: 800px) and (max-height: 600px) {
      align-items: center;

      h2 {
        font-size: 50px;
        line-height: initial;
      }

      p {
        font-size: 22px;
      }
    }
  }

  .nx-header-video {
    background-color: #000;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 0;
    line-height: 0;
    overflow: hidden;
    width: 100%;
    height: 88vh;
    position: absolute;
    top: -100px;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;

    &::after {
      content: "";
      background-color: rgba(0 0 0 / 20%);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    iframe {
      border: none;
      width: 100vw;
      height: calc((var(--vh, 1vh) * 100));
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;

      @media (min-aspect-ratio: 16 / 9) {
        height: 56.25vw;
      }

      @media (max-aspect-ratio: 16 / 9) {
        width: 177.78vh;
      }
    }
  }
}

@media (min-width: 801px) and (max-height: 550px) {
  .header-main {
    min-height: 300px;

    .nx-header-video {
      min-height: 400px;
    }
  }
}

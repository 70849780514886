/* Styles par défaut */
.nx-page-simplecontent-content {
  & a {
    color: #2b2e38;
    text-decoration: none;
    font-size: 16px;

    &::before {
      content: "";
      background-color: #2b2e38;
      mask-image: url("/vendors/images/icons/link-chain.svg");
      vertical-align: middle;
      display: inline-block;
      width: 24px;
      height: 25px;
      position: relative;
      top: -0.1em;
      transition: all 0.5s;
      margin: 0 3px 0 0;
    }
  }

  & a:hover {
    text-decoration: underline;
    color: #2b2e38;

    &::before {
      background-color: #2b2e38;
    }
  }
}

@media (max-width: 768px) {
  .nx-page-simplecontent-content {
    & a {
      font-size: 14px;

      &::before {
        width: 20px;
        height: 20px;
        background-size: cover;
      }
    }

    img.richtext-image {
      width: 100%;
      height: auto;
    }
  }

  .nx-page-simplecontent-content p,
  .nx-page-simplecontent-content h1,
  .nx-page-simplecontent-content h2,
  .nx-page-simplecontent-content h3,
  .nx-page-simplecontent-content h4,
  .nx-page-simplecontent-content h5,
  .nx-page-simplecontent-content h6 {
    margin-bottom: 12px;
  }
}

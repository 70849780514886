/* stylelint-disable no-descending-specificity */
%upcoming-events {
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  background-color: white;

  h2 {
    text-align: left;
    margin-bottom: 65px;
  }

  .event-categories-wrapper {
    .nx-tabs-carets {
      display: none;

      &:hover {
        cursor: pointer;
      }
    }

    .nx-tabs-caret-left {
      width: 24px;
      height: 24px;
    }

    .nx-tabs-caret-right {
      width: 24px;
      height: 24px;
    }

    .event-categories {
      text-align: center;
      list-style: none;
      display: inline-flex;
      cursor: pointer;
      padding: 12px 0;
      margin: 0 auto;

      &::-webkit-scrollbar {
        height: 4px;
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb:horizontal {
        background-color: #cbcbcb;
        border-radius: 10px;
        width: 100px;
        height: 4px;
        cursor: pointer;
      }

      li {
        a {
          border-bottom: 0 solid transparent;
          color: #6c6e79;
          font-size: 16px;
          text-decoration: none;
          text-transform: none;
          white-space: nowrap;
          transition: border 0.2s;
          padding: 0 0 8px;
          margin: 0 16px;
        }

        &.active {
          color: black;
          font-weight: bold;
        }

        &.active,
        &:hover {
          a {
            border-bottom: 3px solid $yellow-dark;

            &.framboise {
              border-color: $framboise;
            }

            &.clementine {
              border-color: $clementine;
            }

            &.bleu-vert {
              border-color: $bleu-vert;
            }

            &.yellow {
              border-color: $yellow;
            }
          }
        }
      }
    }
  }

  .event-tags {
    list-style: none;
    display: block;
    padding: 0;
    margin: 32px auto 50px;

    li {
      white-space: nowrap;
      display: inline-block;
      margin: 10px 4px;

      &:hover {
        a {
          border-color: $yellow-dark;
          background-color: white;
        }
      }

      &.active {
        a {
          border-color: black;
          background-color: black;
          color: white;
        }
      }
    }
  }

  .events-list {
    .thumb-wrapper {
      display: flex;

      .thumb {
        width: 100%;
        min-height: 340px;
        border-radius: 32px;
        background-size: cover;
        background-position: center center;
      }
    }

    .title-wrapper {
      display: flex;

      .decor {
        background-color: #191b22;
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 16px;
        margin-top: 5px;

        &.pink {
          background-color: #ff1044;
        }
      }

      h3 {
        font-size: 24px;
        text-align: left;
        margin-bottom: 0;
      }
    }

    .cont-row {
      display: flex;
      justify-content: space-between;
      margin-top: 18px;
    }

    .cr-col-l {
      text-align: left;
      font-size: 16px;
      min-width: 0;
      padding-left: 47px;
      padding-right: 30px;
    }

    .nx-upcoming-events-date {
      font-size: 14px;
      font-weight: 330;
      line-height: 120%;
      text-transform: uppercase;
      display: flex;
      align-items: center;

      > img {
        margin: 0 6px 0 0;
      }
    }

    .tag-location {
      font-size: 0;
      line-height: 0;
      height: 32px;
      display: flex;
      flex: 1;
      align-content: center;
      width: fit-content;
      min-width: 0;
      max-width: 100%;
      padding: 6px 10px;

      > span {
        font-size: 14px;
        font-weight: 450;
        line-height: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .price-tag {
      border: 1px solid #c4c7cf;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-weight: 450;
      line-height: 100%;
      text-transform: uppercase;
      height: 32px;
      display: flex;
      align-content: center;
      padding: 6px 12px;
      width: fit-content;
    }

    .date-tag {
      font-size: 12px;
      padding: 3px;
      border: 1px solid black;
      border-radius: 4px;
      width: 73px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .margin-top {
    margin-top: 50px;
  }

  .events-list-yellow-wrapper {
    background-color: #fff7ac;
    padding: 33px 0;
    margin-bottom: -50px;

    @media (max-width: 992px) {
      h3 {
        margin-top: 50px;
      }
    }
  }

  %section {
    padding: 33px 0;
    text-align: left;

    h2 {
      font-size: 24px;
      margin-bottom: 0;
    }

    p {
      margin: 30px 0;
    }

    .announcement {
      text-transform: uppercase;
      font-size: 12px;
    }

    .container {
      display: flex;
      flex-direction: column;

      .bs-wrapper {
        display: flex;
        gap: 25px;

        h2 {
          width: calc(100% - 300px);
        }

        .line-side {
          max-width: 300px;
          width: 100%;

          span {
            width: 100%;
            background-color: #fff7ac;
            height: 4px;
            display: block;
          }
        }
      }
    }
  }

  .black-section {
    @extend %section;

    background-color: #191b23;
    color: white;
  }

  .yellow-section {
    background-color: #fff7ac;
    color: #191b23;
    text-align: left;
    padding: 33px 0;
  }

  @media (max-width: 992px) {
    .sm-margin-top-non {
      margin-top: 50px;
    }

    .sm-margin-top-yes {
      margin-top: 50px;
    }

    .i-order-sm-2 {
      order: 2;
    }

    .title-wrapper {
      margin-top: 50px;
    }
  }

  @media (max-width: 824px) {
    .event-categories-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .event-categories {
        white-space: nowrap;
        overflow-x: scroll;
        width: inherit;
        max-width: calc(100% - 15px);
        padding: 10px 10px 20px 5px;
        margin: 0 auto;

        &::-webkit-scrollbar {
          height: 3px;
          width: 4px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: transparent;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        &::-webkit-scrollbar-thumb:horizontal {
          background: #ccc;
          border-radius: 3px;
          width: 100px;
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 400px) {
    .nth-2 {
      align-items: center;

      .cr-col-l {
        .tag-location {
          height: auto;
        }
      }
    }
  }
}

.upcoming-events {
  @extend %upcoming-events;
}

.attraction-list {
  @extend %upcoming-events;

  h3 {
    text-align: left;
    font-size: 24px;
  }

  .title-wrapper {
    .line-side {
      max-width: 100px;
      width: 100%;

      span {
        width: 100%;
        height: 4px;
        display: block;
      }
    }

    h3 {
      width: calc(100% - 100px);
    }
  }

  .events-list {
    .cr-col-l {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .announcement {
    text-transform: uppercase;
    font-size: 12px;
    text-align: left;
    margin-top: 23px;
  }

  @media (max-width: 767px) {
    .title-wrapper {
      h3 {
        width: calc(100% - 50px);
      }

      .line-side {
        max-width: 50px;
      }
    }

    .black-section {
      .container {
        .bs-wrapper {
          .line-side {
            max-width: 50px;
          }

          h2 {
            width: calc(100% - 50px);
          }
        }
      }
    }
  }
}

.contacts-list {
  @extend %upcoming-events;

  .events-list {
    .title-wrapper {
      h3 {
        color: #191b23;
        font-size: 38px;
        font-weight: 350;
        line-height: 120%;
      }

      .decor {
        background-color: #fff7ac;
      }
    }

    .cr-col-l {
      font-size: 24px;
      font-weight: 350;
      max-width: 100%;

      .nx-contact-description {
        color: #6c6e79;

        p {
          font-size: 24px;
          font-weight: 350;
          line-height: 120%;
        }

        p:last-child {
          margin: 0;
        }
      }

      .nx-contact-address {
        font-size: 16px;
        position: relative;
        padding: 0 0 0 26px;
        margin: 14px 0 16px;

        &::before {
          content: "";
          background-image: url("/vendors/images/icons/contact-location.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          vertical-align: middle;
          display: inline-block;
          width: 24px;
          height: 24px;
          position: absolute;
          top: 0;
          left: 0;
          margin: 0 2px 3px 0;
        }
      }

      .nx-contact-website a {
        color: #6c6e79;
        font-size: 16px;
        font-weight: 450;
        text-decoration: none;
        text-transform: uppercase;
        word-break: break-word;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
